.legalInformation {
  --legalInformation-margin: 1.5rem 0 2.5rem; /* 24px 0 40px */

  @mixin tvDevice {
    --legalInformation-margin: 1.5rem 0 3rem; /* 24px 0 48px */
  }
  margin: var(--legalInformation-margin);

  list-style: none;

  &__item {
    margin-bottom: 1rem;

    &--readonly {
      margin-bottom: 1.5rem;
    }
  }

  &__link {
    /* TODO: Change @DIVE/Link to be inline instead of inline-block */
    display: inline !important; /* to avoid link block wrap when too long */
  }

  &__alertBox {
    margin-top: 1rem;
  }
}
