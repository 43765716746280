.voucherEntryBar {
  --color_voucherEntryBar-bg: var(--color_dark);
  --color_voucherEntryBar-border: var(--color_tuna);
  --color_voucherEntryBar-placeholder-fg: var(--color_white-50);

  --voucherEntryBar-input-max-width: 100%;

  @media (--mq-from-desktop) {
    --voucherEntryBar-input-max-width: 42.125rem;
  }

  [data-theme~='telecomitalia'] & {
    --color_voucherEntryBar-bg: var(--color_gold-dark-primary);
    --color_voucherEntryBar-placeholder-fg: var(--color_white);
  }

  display: flex;
  min-height: 3.125rem;

  &__input {
    width: 100%;
    padding: 0.9375rem;
    font-size: 1rem;
    color: var(--color_white);
    background-color: var(--color_voucherEntryBar-bg);
    border: 1px solid var(--color_voucherEntryBar-border);
    border-radius: 0.125rem;

    &::placeholder {
      color: var(--color_voucherEntryBar-placeholder-fg);
    }

    &:focus {
      border-color: var(--color-white);
    }

    [data-theme~='telecomitalia'] & {
      border: none;
    }

    &--error {
      border-color: hsla(358, 81%, 58%, 1);
    }

    &Wrapper {
      display: flex;
      flex-direction: column;
      width: var(--voucherEntryBar-input-max-width);
    }
  }

  &__button {
    /* Override dive style height (check comments here : https: //www.figma.com/design/ysKH7vlCT8Dq63IdIV6RJW/TVOD?node-id=10422-65786&t=OxHFn2OlNLQL5mBe-1) */
    height: 3.125rem !important;
    margin-left: 1.25rem;
  }
}
