.funnelContent {
  &__wrapper {
    --funnelContent-padding: 3.75rem 0.9375rem 12rem;
    --funnelContent-error-h1-font-size: 1.5rem;

    @media (--mq-from-tablet) {
      --funnelContent-padding: 3.75rem 5rem; /** 60px 80px */
      --funnelContent-error-h1-font-size: 2rem;
    }

    @mixin tvDevice {
      --funnelContent-error-h1-font-size: 3rem;
      --funnelContent-padding: 5.625rem 0;

      &--no-top-padding {
        --funnelContent-padding: 0 5rem 3.75rem !important;
      }
    }

    display: flex;
    justify-content: center;
    width: 100%;
    height: fit-content;
    min-height: inherit;
    padding: var(--funnelContent-padding);
  }

  &__content {
    @media (--mq-from-tablet) {
      --funnelContent-max-width: 27.75rem; /* 444px */
    }

    @media (--mq-from-laptop) {
      --funnelContent-max-width: 46rem; /* 736px */
    }

    @media (--mq-from-desktop) {
      --funnelContent-max-width: 60rem; /* 960px */
    }

    @mixin tvDevice {
      --funnelContent-max-width: 65rem; /* 1040px */
    }

    display: flex;
    justify-content: center;
    width: 100%;
    max-width: var(--funnelContent-max-width);

    &--error {
      align-items: flex-start;
      justify-content: flex-start;

      h1 {
        margin-top: 0;
        font-family: var(--font_canal);
        font-size: var(--funnelContent-error-h1-font-size);
        color: var(--color-white);
        text-align: left;
        text-transform: uppercase;
      }
    }
  }
}
