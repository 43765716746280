@import '../../../assets/base_css/_animations.css';

.funnelModal {
  --color_funnelModal-bg: var(--color_dark);
  --top-backButton: 0.9375rem; /* 15px */
  --left-backButton: 0.9375rem; /* 15px */
  --min-height-header: var(--size_mobileHeader_height);

  @media (--mq-from-tablet) {
    --min-height-header: var(--size_header_height);
  }

  @mixin tvDevice {
    --min-height-header: var(--size_header_height-tv);
  }

  [data-theme~='telecomitalia'] & {
    --color_funnelModal-bg: var(--color_gold-kumera);
  }
  position: relative;

  display: flex;
  height: 100%; /* modal-body scrollable, avoid min-height */
  background-color: var(--color_funnelModal-bg);

  &--fullPage {
    min-height: calc(
      100vh - var(--min-height-header)
    ); /* minus header height */
  }

  &__backButton {
    position: absolute;
    top: var(--top-backButton);
    left: var(--left-backButton);
  }

  &__spinner__backdrop {
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
      width: 100%;
      height: 100%;
      content: '';
      background-color: var(--color_funnelModal-bg);
      opacity: 0;
      animation: 0.3s opacity-50 ease-in-out 1s normal 1 forwards;
    }
  }
}

.funnelModal.funnelModal {
  padding: 0;
}
