.offerHeader {
  --offerHeader-title-color-fg: var(--color_text-primary);
  --offerHeader-title-font-size: 1.375rem;
  --offerHeader-title-height: 1.625rem;
  --offerHeader-title-margin-bottom: 0.25rem;
  --offerHeader-title-line-height: 1.625rem;
  --offerHeader-subtitle-font-size: 1rem;
  --offerHeader-subtitle-height: 1.25rem;
  --offerHeader-subtitle-line-height: 1.25rem;
  --offerHeader-subtitle-color-fg: hsla(41, 96%, 49%, 1); /* #F6A805 */
  --offerHeader-circle-size: 3.125rem;
  --offerHeader-circle-border-color: var(--color_tuna);
  --offerHeader-logo-width: 3rem;
  --offerHeader-logo-height: 2.375rem;
  --offerHeader-image-min-width: 5.625rem;
  --offerHeader-image-width: 6.5625rem;
  --offerHeader-image-height: 7.5rem;
  --offerHeader-technicalInfos-margin: 0.5rem;
  --offerHeader-technicalInfos-svg-height: 1.1875rem;
  --offerHeader-price-line-height: 1.375rem;
  --offerHeader-textWrapper-margin-left: 1.0625rem;

  &--paymentMeans {
    --offerHeader-title-font-size: 1.125rem;
    --offerHeader-title-line-height: 1.375rem;
    --offerHeader-title-margin-bottom: 0.125rem;
    --offerHeader-textWrapper-margin-left: 1.25rem;
    --offerHeader-subtitle-margin-bottom: 0.125rem;
    --offerHeader-subtitle-line-height: 1.25rem;
  }

  &--withoutSubtitle {
    --offerHeader-title-margin-bottom: 0;
  }

  [data-theme~='telecomitalia'] & {
    --offerHeader-circle-border-color: var(--color_white-50);
    --offerHeader-subtitle-color-fg: var(--color_white-50);

    &--paymentMeans {
      --offerHeader-subtitle-color-fg: var(--color_white-50);
    }
  }

  @media (--mq-from-desktop) {
    --offerHeader-title-font-size: 1.625rem;
    --offerHeader-title-height: 1.875rem;
    --offerHeader-title-line-height: 1.875rem;
    --offerHeader-subtitle-font-size: 1rem;
    --offerHeader-subtitle-line-height: 1.25rem;
    --offerHeader-circle-size: 4rem;
    --offerHeader-logo-width: 3.75rem;
    --offerHeader-logo-height: 2.8125rem;
    --offerHeader-image-width: 5.625rem;
    --offerHeader-technicalInfos-margin: 0.625rem;
    --offerHeader-price-line-height: 1.625rem;
    --offerHeader-textWrapper-margin-left: 1.625rem;

    &--paymentMeans {
      --offerHeader-title-font-size: 1.375rem;
      --offerHeader-title-margin-bottom: 0.25rem;
      --offerHeader-title-line-height: 1.625rem;
      --offerHeader-subtitle-margin-bottom: 0.25rem;
      --offerHeader-textWrapper-margin-left: 1.5rem;
    }
  }

  @mixin tvDevice {
    --offerHeader-title-font-size: 2.25rem;
    --offerHeader-title-height: 2.5rem;
    --offerHeader-title-line-height: 2.5rem;
    --offerHeader-subtitle-height: 2rem;
    --offerHeader-subtitle-font-size: 1.6875rem;
    --offerHeader-subtitle-line-height: 2rem;
    --offerHeader-subtitle-margin-bottom: 0;
    --offerHeader-circle-size: 5.6875rem;
    --offerHeader-logo-width: 5.3125rem;
    --offerHeader-logo-height: 4rem;
    --offerHeader-image-min-width: 6.5625rem;
    --offerHeader-image-width: 6.5625rem;
    --offerHeader-image-height: 8.75rem;
    --offerHeader-technicalInfos-svg-height: 1.75rem;
    --offerHeader-price-line-height: 2.5rem;
    --offerHeader-textWrapper-margin-left: 1.25rem;

    &--paymentMeans {
      --offerHeader-title-color-fg: var(--color_white-80);
      --offerHeader-title-font-size: 2.25rem;
      --offerHeader-textWrapper-margin-left: 3rem;
      --offerHeader-subtitle-line-height: 2rem;
      --offerHeader-subtitle-margin-bottom: 1rem;
      --offerHeader-title-margin-bottom: 0;
    }
  }

  display: flex;
  align-items: center;

  &__logo {
    display: flex;
    width: var(--offerHeader-circle-size);
    height: var(--offerHeader-circle-size);
    border: 0.0625rem solid var(--offerHeader-circle-border-color);
    border-radius: 50%;
  }

  &__logo img {
    min-width: var(--offerHeader-logo-width);
    height: var(--offerHeader-logo-height);
    margin: auto;
  }

  &__image {
    width: var(--offerHeader-image-width);
    min-width: var(--offerHeader-image-min-width);
    height: var(--offerHeader-image-height);
  }

  &__textWrapper {
    margin-left: var(--offerHeader-textWrapper-margin-left);
  }

  &__title {
    margin-bottom: var(--offerHeader-title-margin-bottom);
    font-size: var(--offerHeader-title-font-size);
    font-weight: 600;
    line-height: var(--offerHeader-title-line-height);
    color: var(--offerHeader-title-color-fg);
  }

  &__subtitle {
    margin-top: 0;
    margin-bottom: var(--offerHeader-subtitle-margin-bottom);
    font-size: var(--offerHeader-subtitle-font-size);
    line-height: var(--offerHeader-subtitle-line-height);
    color: var(--offerHeader-subtitle-color-fg);
  }

  &__technicalInfos {
    align-items: center;
    vertical-align: middle;

    svg {
      height: var(--offerHeader-technicalInfos-svg-height);
      margin-right: var(--offerHeader-technicalInfos-margin);
      vertical-align: middle;
      fill: var(--color_white-50);
    }

    & > :first-child {
      margin-left: var(--offerHeader-technicalInfos-margin);
    }
  }

  &__price {
    line-height: var(--offerHeader-price-line-height);
  }
}
