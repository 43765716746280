.voucherStep {
  margin-right: auto;

  &__virtualKeyboardWrapper {
    position: absolute;
    bottom: 1.25rem;
    left: 0;
    width: 100%;
    overflow: hidden;
  }
}
