.SafetyCodeInputFieldDesktop {
  --safetyCodeForm_height: 4.0625rem;
  --safetyCodeForm_neutralColor: var(--color_grey-200);

  --color_input-light-bg: var(--background-page);
  --color_input-light-fg: var(--color_font-base);
  --color_input-placeholder-light-fg: hsl(0, 0%, 45.9%); /* #757575 */
  --color_input-dark-bg: var(--color_darkmode-secondary);
  --color_input-dark-fg: var(--color_darkmode-textcolor-primary);
  --color_input-dark-focus-bg: var(--color_black);
  --color_input-dark-focus-fg: var(--color_darkmode-textcolor-primary);
  --color_input-placeholder-dark-fg: var(--color_darkmode-textcolor-primary);
  --color-button-bg: var(--color_amaranth);
  --color-button-fg: var(--color_white);

  [data-theme~='telecomitalia'] & {
    --color_input-light-bg: var(--color_gold-dark-primary);
    --color_input-light-fg: var(--color_white);
    --color_input-placeholder-light-fg: var(--color_white-50);
    --color_input-dark-bg: var(--color_gold-dark-primary);
    --color_input-dark-fg: var(--color_white);
    --color_input-dark-focus-bg: var(--color_gold-dark-primary);
    --color_input-dark-focus-fg: var(--color_white);
    --color_input-placeholder-dark-fg: var(--color_white-50);
    --color-button-bg: var(--color_white);
    --color-button-fg: var(--color_black);
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 29.375rem;

  @media (--mq-from-laptop) {
    margin: 1.25rem 0;

    &__locked {
      margin: 2.5rem 1.25rem;
    }
  }

  &__title {
    margin: 0 0 0.375rem;
    font-size: 1.625rem;
    text-align: center;
  }

  &__subtitle {
    margin-top: 0;
    margin-bottom: 1.0625rem;
    color: var(--color_white-80);
    text-align: center;
  }

  &__codeInput {
    display: flex;
    justify-content: space-between;
    width: 13.875rem;
  }

  &__button {
    padding: 0.8125rem 1rem;
    margin-top: 0.4375rem;
    color: var(--color-button-fg);
    text-align: center;
    background-color: var(--color-button-bg);
    box-shadow: 0 0.1875rem 0.5625rem rgba(0, 0, 0, 0.5);
  }

  &__forgotten {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0.625rem 0;
    font-family: var(--font_hind);
    font-weight: 600;
  }

  &__forgottenLink {
    display: flex;
    align-items: center;
    font-size: 0.9375rem;
    color: var(--color_secondary);

    &:hover {
      font-style: italic;
      cursor: pointer;

      + .SafetyCodeInputFieldDesktop__tooltip {
        visibility: visible;
        opacity: 1;
      }
    }

    [data-theme~='telecomitalia'] & {
      text-decoration: underline;
    }

    svg {
      margin: 0 0 0.1875rem 0.6875rem;
    }
  }

  &__tooltip {
    position: absolute;
    bottom: 100%;
    display: block;
    visibility: hidden;
    font-weight: normal;
    border: 1px solid var(--safetyCodeForm_neutralColor);
    opacity: 0;
    transition: opacity 250ms ease;
  }

  &__errorMessage {
    display: flex;
    flex-direction: row;
    padding: 0.75rem 0.9375rem;
    margin: 0.625rem 0;
    font-size: 0.875rem;
    background-color: hsla(358, 81%, 58%, 0.2);

    svg {
      @mixin size 1.25rem;
      margin-right: 1.25rem;
    }
  }
}
