.SafetyCodeInputFieldTV {
  --color_inputItem-bg: var(--color_white-50);
  --color_inputItemNext-bg: var(--color_white);
  --color_inputItemChecked-bg: var(--color_mine-shaft);

  [data-theme~='telecomitalia'] & {
    --color_inputItem-bg: var(--color_gold-dark-primary);
    --color_inputItemNext-bg: var(--color_gold-dark-tertiary);
    --color_inputItemChecked-bg: var(--color_white);
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 15.875rem;

  &__inputContainer {
    display: flex;
    flex-direction: row;
    margin-bottom: 1.875rem;
  }

  &__inputItem {
    @mixin size 6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color_inputItem-bg);
    border-radius: var(--border-radius-extraLarge);

    &:not(:last-child) {
      margin-right: 1.5rem;
    }
  }

  &__inputItemNext {
    background-color: var(--color_inputItemNext-bg);
    transform: scale(1.07);
  }

  &__inputItemChecked {
    @mixin size 1.625rem;
    background-color: var(--color_inputItemChecked-bg);
    border-radius: 0.8125rem;
  }
}
