.minorOffers {
  --minorOffers-item-margin-bottom: 0.5rem;

  @media (--mq-from-desktop) {
    --minorOffers-item-margin-bottom: 0.75rem;
  }

  @mixin tvDevice {
    --minorOffers-item-margin-bottom: 1rem;
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: var(--minorOffers-item-margin-bottom);
    }
  }
}
