.majorOffers {
  --majorOffers-flex-direction: column;
  --majorOffers-gap: 0.5rem;
  --majorOffers-last-margin-left: 0;
  --majorOffers-last-margin-top: var(--majorOffers-gap);

  @media (--mq-from-laptop) {
    --majorOffers-flex-direction: row;
    --majorOffers-last-margin-left: var(--majorOffers-gap);
    --majorOffers-last-margin-top: 0;
  }

  @media (--mq-from-desktop) {
    --majorOffers-gap: 0.75rem;
  }

  @mixin tvDevice {
    --majorOffers-gap: 2rem;
  }

  & > *:nth-child(2) {
    margin-top: var(--majorOffers-last-margin-top);
    margin-left: var(--majorOffers-last-margin-left);
  }

  display: flex;
  flex-direction: var(--majorOffers-flex-direction);
  width: 100%;
}
