.voucherDetail {
  --voucherDetail-padding: 1rem;
  --voucherDetail-margin-bottom: 0.5rem;
  --voucherDetail-title-font-size: 1rem;
  --voucherDetail-description-fg-color: rgba(255, 255, 255, 0.5) /* ffffff80 */;
  --voucherDetail-description-font-size: 0.875rem;
  --voucherDetail-description-line-height: 1.125rem;
  --voucherDetail-button-margin: 0.5rem 0 0 auto;
  --voucherDetail-button-padding: 0.75rem;
  --voucherDetail-content-max-width: auto;
  --voucherDetail-content-margin-right: 0;
  --voucherDetail-border-color: var(--color_tuna);

  [data-theme~='telecomitalia'] & {
    --voucherDetail-border-color: var(--color_white-50);
  }

  @media (--mq-from-tablet) {
    --voucherDetail-padding: 1.25rem;
    --voucherDetail-margin-bottom: 1rem;
    --voucherDetail-description-font-size: 1rem;
    --voucherDetail-description-line-height: 1.25rem;
    --voucherDetail-button-margin: 0 1.25rem 0 auto;
    --voucherDetail-button-padding: 0.75rem 0 0.75rem 0.75rem;
  }

  @mixin tvDevice {
    --voucherDetail-padding: 1.5rem 2rem;
    --voucherDetail-title-font-size: 1.875rem;
    --voucherDetail-description-font-size: 1.875rem;
    --voucherDetail-description-line-height: 2.25rem;
    --voucherDetail-content-max-width: 47rem;
    --voucherDetail-button-margin: 0 0 0 auto;
    --voucherDetail-button-padding: 0;
    --voucherDetail-content-margin-right: 1.25rem;
  }
  display: flex;
  flex-direction: column;

  width: 100%;
  padding: var(--voucherDetail-padding);
  margin-bottom: var(--voucherDetail-margin-bottom);
  border: 1px solid var(--voucherDetail-border-color);
  border-radius: 0.125rem;

  @media (--mq-from-xsmall-420) {
    flex-direction: row;
  }

  &__content {
    display: flex;
    flex-direction: column;
    max-width: var(--voucherDetail-content-max-width);
    margin-right: var(--voucherDetail-content-margin-right);

    &--fullWidth {
      max-width: 100%;
    }
  }

  &__title {
    font-size: var(--voucherDetail-title-font-size);
  }

  &__description {
    font-size: var(--voucherDetail-description-font-size);
    line-height: var(--voucherDetail-description-line-height);
    color: var(--voucherDetail-description-fg-color);

    span {
      display: block;
    }
  }

  &__button {
    align-self: center;
    padding: var(--voucherDetail-button-padding);
    margin: var(--voucherDetail-button-margin);

    @mixin tvDevice {
      min-width: 12.9375rem;
    }
  }
}
