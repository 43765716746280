.deportedPayment {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;

  &--hidden {
    display: none;
  }

  &--center {
    justify-content: center;
  }

  &__header,
  &__title,
  &__footer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__header {
    margin-top: auto;

    &--center {
      margin-top: initial;
    }
  }

  &__title {
    margin-bottom: 4rem;

    &--center {
      margin-bottom: 2rem;
      text-align: center;
    }
  }

  &__subtitle {
    margin-top: 2.25rem;

    span {
      font-size: 1.875rem;
    }
  }

  &__QRCode {
    width: 35%;
  }

  &__footer {
    width: 100%;
    margin-top: auto;
  }

  &__description {
    width: auto;
    margin-bottom: 5.25rem;
    font-size: 1.875rem;
    color: var(--color_white-50);
  }

  &__binder {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__button {
    position: absolute;
    bottom: 5.5625rem;
  }
}
