.SafetyCodeInputItem {
  --color_input-parentale-fg: var(--color_darkmode-line);
  --border-input-focus: 0.125rem solid var(--color_parental-code-input-border);

  [data-theme~='dark'] & {
    --color_usermenu-border: var(--color_darkmode-secondary);
    --color_usermenu-button-secondary-bg: var(--color_darkmode-secondary);
    --color_usermenu-button-secondary-bg-hover: var(--color_darkmode-tertiary);
    --color_usermenu-button-secondary-fg: var(--color_white);
    --color_usermenu-button-secondary-fg-hover: var(--color_white);
    --color_usermenu-button-border: transparent;
    --color_input-parentale-fg: var(--color_input-dark-fg);
  }

  [data-theme~='telecomitalia'] & {
    --color_input-parentale-fg: var(--color_white);
    --border-input-focus: 0.056249999999999994rem solid var(--color_white-50);
    --color-input-bg: var(--color_gold-dark-secondary);
    --color_parental-code-input-bg: var(--color_gold-dark-secondary);
  }

  @mixin size 3rem;

  &--filled {
    position: relative;

    /* Password caret as a white circle */
    &::before {
      position: absolute;
      /* translate center */
      top: 50%;
      left: 50%;
      pointer-events: none;
      content: '';
      background-color: var(--color_input-parentale-fg);
      border-radius: 0.75rem;
      transform: translate(-50%, -50%);
      @mixin size 0.75rem;
    }
  }

  input {
    @include size 3rem;
    padding: 0;
    font-family: TextSecurity, sans-serif;
    font-size: 3rem;
    color: transparent;

    /* Fake password input - iOS */
    text-align: center;

    /* Removing spin buttons from inputs on Firefox */
    -moz-appearance: textfield;
    background-color: var(--color_parental-code-input-bg);
    border: none;
    border-radius: 2px;
    -webkit-text-security: disc;

    /* Removing spin buttons from inputs on webkit browsers */
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      margin: 0;
      -webkit-appearance: none;
      appearance: none;
    }

    &:focus {
      caret-color: transparent;
      border: var(--border-input-focus);
    }
  }

  label {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    /* allow to hide label, it's useful for acessibility */
    border: 0;
    clip-path: circle(0);
  }
}
