.tvodTitle {
  --tvodTitle-title-font-size: 1.5rem;
  --tvodTitle-subtitle-font-size: 1rem;
  --tvodTitle-subtitle-icon-height: 1.25rem;
  --tvodTitle-subtitle-margin-left: 0.25rem;
  --tvodTitle-subtitle-margin-top: 0.375rem;
  --tvodTitle-subtitle-color: var(--color_white-50);
  --tvodTitle-subtitle-vertical-align: sub;

  @media (--mq-from-desktop) {
    --tvodTitle-title-font-size: 2rem;
  }

  @mixin tvDevice {
    --tvodTitle-title-font-size: 3rem;
    --tvodTitle-subtitle-font-size: 1.5rem;
    --tvodTitle-subtitle-margin-left: 0.5rem;
    --tvodTitle-subtitle-margin-top: 1.25rem;
    --tvodTitle-subtitle-icon-height: 2.25rem;
    --tvodTitle-subtitle-vertical-align: text-bottom;
  }

  display: flex;
  flex-direction: column;

  &__title {
    font-family: var(--font_canal);
    font-size: var(--tvodTitle-title-font-size);
    color: var(--color_text-primary);
    text-transform: uppercase;
  }

  &__subtitle {
    margin-top: var(--tvodTitle-subtitle-margin-top);
    color: var(--tvodTitle-subtitle-color);

    &--icon {
      path {
        fill: var(--tvodTitle-subtitle-color);
      }

      height: var(--tvodTitle-subtitle-icon-height);
      vertical-align: var(--tvodTitle-subtitle-vertical-align);
    }

    &--label {
      font-size: var(--tvodTitle-subtitle-font-size);
    }

    &--with-icon {
      margin-left: var(--tvodTitle-subtitle-margin-left);
    }
  }
}
