.SafetyCodeInput {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 68.75rem;
  height: 100vh;
  padding: 10rem 0 3.75rem;

  &--locked {
    justify-content: center;

    .SafetyCodeInput__subtitle {
      color: var(--color_white-50);
    }
  }

  &__inputAndTitleContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__titleContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    margin: 0 0 1rem;
    font-family: var(--font_canal);
    font-size: 3rem;
    text-transform: uppercase;
  }

  &__subtitle {
    margin: 0 0 3rem;
    font-size: 1.875rem;
    color: var(--color_white-80);
    text-align: center;
  }

  &__button {
    height: 3.3125rem;
    padding: 0.0625rem 0.75rem 0.125rem 0.75rem;
    margin-bottom: 4.3125rem;
    font-size: 1.75rem;
    border-radius: var(--border-radius-extraLarge);
  }
}

:global {
  @mixin tvDevice {
    .SafetyCodeForgottenButtonFocus.tv__focus {
      @mixin tv-focus-button-with-shadow;
    }
  }
}
