.TvodForm {
  --TvodForm-height: 100vh;
  --TvodForm-padding: 3.75rem 1rem 0;
  --TvodForm-title-margin-bottom: 1.75rem;
  --TvodForm-offerHeader-margin-bottom: 1rem;
  --TvodForm-icon-width: 1.1875rem;
  --TvodForm-icon-height: 2.0625rem;
  --TvodForm-icon-margin: auto 0 auto 1.5rem;
  --TvodForm-footerDescription-color: var(--color_white-50);
  --TvodForm-footerDescription-margin-top: 1rem;
  --TvodForm-footerDescription-margin-bottom: 1rem;
  --TvodForm-footerDescription-font-size: 1rem;
  --TvodForm-footerDescription-line-height: 1.25rem;

  @media (--mq-from-tablet) {
    --TvodForm-height: calc(100vh - 3.125rem);
    --TvodForm-padding: 3.75rem 5rem 0;
  }

  @media (--mq-from-desktop) {
    --TvodForm-title-margin-bottom: 1.5rem;
    --TvodForm-footerDescription-margin-top: 1.5rem;
    --TvodForm-footerDescription-margin-bottom: 1.5rem;
  }

  @mixin tvDevice {
    --TvodForm-padding: 3.75rem 0 0;
    --TvodForm-title-margin-bottom: 2rem;
    --TvodForm-offerHeader-margin-bottom: 2rem;
    --TvodForm-footerDescription-margin-top: 3rem;
    --TvodForm-footerDescription-margin-bottom: 3rem;
    --TvodForm-footerDescription-font-size: 1.875rem;
    --TvodForm-footerDescription-line-height: 2.25rem;
  }

  width: 100%;

  &__title,
  &__alertBox {
    margin-bottom: var(--TvodForm-title-margin-bottom);
  }

  &__offerHeader {
    margin-bottom: var(--TvodForm-offerHeader-margin-bottom);
  }

  &__footerDescription {
    margin-top: var(--TvodForm-footerDescription-margin-top);
    margin-bottom: var(--TvodForm-footerDescription-margin-bottom);
    font-size: var(--TvodForm-footerDescription-font-size);
    line-height: var(--TvodForm-footerDescription-line-height);
    color: var(--TvodForm-footerDescription-color);
  }
}
