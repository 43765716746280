.voucherInput {
  --color_voucher-focus-border: var(--color_amaranth);

  [data-theme~='telecomitalia'] & {
    --color_voucher-focus-border: var(--color_gold-dark-tertiary);
  }

  &__input {
    width: 100%;
    height: 69px;
    padding: 0 1.25rem;
    margin: 0 auto;
    margin-bottom: 7.5rem;
    font-size: 2.625rem;
    letter-spacing: -0.125rem;
    pointer-events: none;
    border: 4px solid transparent;
    border-radius: 8px;
    transition: 300ms ease-in-out;

    &:focus,
    &--focus {
      border-color: var(--color_voucher-focus-border);
      transform: scale(1.05);
    }
  }
}
