.majorOffer {
  --majorOffer-min-height: auto;
  --majorOffer-title-font-size: 1.125rem;
  --majorOffer-subtitle-font-size: 1rem;
  --majorOffer-text-wrapper-bottom: 1rem;
  --majorOffer-title-line-height: 1.375rem;
  --majorOffer-subtitle-line-height: 1.25rem;
  --majorOffer-subtitle-color-fg: var(--color_white-80);
  --majorOffer-padding: 1.5rem;

  @media (--mq-from-desktop) {
    --majorOffer-min-height: auto;
    --majorOffer-title-font-size: 1.375rem;
    --majorOffer-text-wrapper-bottom: 0.75rem;
    --majorOffer-title-line-height: 1.625rem;
    --majorOffer-subtitle-line-height: 1.25rem;
  }

  @mixin tvDevice {
    --color_funnelTvod-offerItem-bg: var(--color_mine-shaft);
    --majorOffer-min-height: 10.75rem;
    --majorOffer-title-font-size: 2.25rem;
    --majorOffer-subtitle-font-size: 1.875rem;
    --majorOffer-title-line-height: 2.6rem;
    --majorOffer-subtitle-line-height: 2.3375rem;
    --majorOffer-subtitle-color-fg: var(--color_white-50);
    --majorOffer-padding: 2rem 1.5rem 1.5rem;
  }

  [data-theme~='telecomitalia'] & {
    --color_funnelTvod-offerItem-bg: var(--color_gold-dark-primary);

    background-color: var(--color_funnelTvod-offerItem-bg);

    @mixin tvDevice {
      --color_funnelTvod-offerItem-bg: var(--color_gold-dark-secondary);

      background-color: var(--color_funnelTvod-offerItem-bg);
    }
  }

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: initial;
  justify-content: space-between;
  width: 100%;
  min-height: var(--majorOffer-min-height);
  padding: var(--majorOffer-padding);
  background-color: var(--color_cod-grey);
  border-radius: 0.25rem;

  &__textWrapper {
    display: flex;
    margin-bottom: var(--majorOffer-text-wrapper-bottom);
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 21.5rem;
    margin-right: 2rem;

    &__title {
      font-size: var(--majorOffer-title-font-size);
      font-weight: 600;
      line-height: var(--majorOffer-title-line-height);
      color: var(--color_text-primary);
      text-align: left;
    }

    &__subtitle {
      font-size: var(--majorOffer-subtitle-font-size);
      line-height: var(--majorOffer-subtitle-line-height);
      color: var(--majorOffer-subtitle-color-fg);
      text-align: start;
    }
  }

  &__offerPrice {
    display: flex;
    align-content: flex-end;
    margin-left: auto;
  }

  &__button {
    margin-left: auto;
  }
}

/* TV focus style */
:global {
  @mixin tvDevice {
    .globalMajorOffer.tv__focus {
      border-radius: var(--border-radius-big-outline);
      box-shadow: 0 0 0 0.3125rem var(--color_focus-border);
      transform: scale(1.04);
    }
  }
}
