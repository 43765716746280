.SafetyCodeTooltip {
  z-index: 1;
  padding: 0 0.625rem;
  overflow: auto;
  font-size: 0.8125rem;
  color: var(--color_primary);
  text-align: center;
  background: var(--color_white);

  span {
    color: var(--color_secondary);

    [data-theme~='telecomitalia'] & {
      font-family: var(--font_hind);
      font-weight: 600;
      color: var(--color_primary);
    }
  }
}
