.offerPrice {
  --offerPrice-font-size: 1.125rem;
  --offerPrice-major-font-size: 1.125rem;
  --offerPrice-minor-font-size: 1rem;
  --offerPrice-promotional-major-margin-left: 0.5rem;
  --offerPrice-promotional-minor-margin-left: 0.5rem;

  --color_offerPrice-minor-fg: var(--color_white-80);

  @media (--mq-from-desktop) {
    --offerPrice-font-size: 1.375rem;
    --offerPrice-major-font-size: 1.375rem;
  }

  @mixin tvDevice {
    --offerPrice-font-size: 2.25rem;
    --offerPrice-major-font-size: 1.9375rem;
    --offerPrice-minor-font-size: 1.875rem;
    --offerPrice-promotional-major-margin-left: 0.625rem;
    --offerPrice-promotional-minor-margin-left: 0.75rem;
  }

  &__initialPrice {
    font-size: var(--offerPrice-font-size);
    color: var(--color_text-primary);

    &--major {
      font-size: var(--offerPrice-major-font-size);
      color: var(--color_text-primary);
    }

    &--minor {
      font-size: var(--offerPrice-minor-font-size);
      color: var(--color_offerPrice-minor-fg);
    }

    &--strikethrough {
      color: var(--color_white-50);
    }
  }

  &__promotionalPrice {
    margin-left: 0.625rem;
    font-size: var(--offerPrice-font-size);
    color: var(--color_text-primary);

    &--major {
      margin-left: var(--offerPrice-promotional-major-margin-left);
      font-size: var(--offerPrice-major-font-size);
    }

    &--minor {
      margin-left: var(--offerPrice-promotional-minor-margin-left);
      font-size: var(--offerPrice-minor-font-size);
      color: var(--color_offerPrice-minor-fg);
    }
  }

  &__ariaLabel {
    position: absolute;
    opacity: 0;
  }
}
