.CrmInformation {
  --crmInformation-item-border-radius: 0.125rem; /* 2px */
  --crmInformation-wrapper-margin-bottom: 1.5rem; /* 24px */
  --crmInformation-item-padding: 1.5rem; /* 24px */
  --crmInformation-item-border-color: var(--color_mine-shaft);
  --crmInformation-deleteButton-margin-top: 2rem; /* 32px */

  [data-theme~='telecomitalia'] & {
    --crmInformation-item-border-color: var(--color_white-50);
  }

  @mixin tvDevice {
    --crmInformation-item-border-radius: 0.5rem; /* 8px */
    --crmInformation-wrapper-margin-bottom: 2rem; /* 32px */
    --crmInformation-item-padding: 1.5rem 2rem;
  }
  margin-bottom: var(--crmInformation-wrapper-margin-bottom);

  list-style: none;

  &__deleteButton {
    margin-top: var(--crmInformation-deleteButton-margin-top);
  }

  &__item {
    padding: var(--crmInformation-item-padding);
    border: 0.0625rem solid var(--crmInformation-item-border-color);

    &:not(:last-child) {
      border-bottom: none;
    }

    &:first-child {
      border-top-left-radius: var(--crmInformation-item-border-radius);
      border-top-right-radius: var(--crmInformation-item-border-radius);
    }
    &:last-child {
      border-bottom-right-radius: var(--crmInformation-item-border-radius);
      border-bottom-left-radius: var(--crmInformation-item-border-radius);
    }

    /* CrmInformation Standalone */
    &__label {
      color: var(--color_white);
    }

    &__subLabel {
      color: var(--color_white-50);
    }

    @mixin tvDevice {
      &__label {
        font-size: 1.875rem; /* 30px */
        line-height: 2.25rem; /* 36px */
      }
      &__subLabel {
        font-size: 1.875rem; /* 30px */
        line-height: 2.25rem; /* 36px */
      }
    }
  }
}
