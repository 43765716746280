.contextualOffer {
  --contextualOffer-title-margin-bottom: 1.75rem;
  --contextualOffer-offerHeader-margin-bottom: 1rem;
  --contextualOffer-majorOffers-margin-bottom: 0.5rem;
  --contextualOffer-minorOffers-margin-bottom: 1.5rem;
  --contextualOffer-seasonsTabs-margin-bottom: 1.5rem;
  --contextualOffer-voucher-margin-bottom: 0.5rem;
  --contextualOffer-alertBox-margin-bottom: 1.5rem;
  --contextualOffer-voucher-max-width: 49.5rem; /* 792px */

  @media (--mq-from-desktop) {
    --contextualOffer-title-margin-bottom: 1.5rem;
    --contextualOffer-majorOffers-margin-bottom: 0.75rem;
  }

  @mixin tvDevice {
    --contextualOffer-title-margin-bottom: 2rem;
    --contextualOffer-offerHeader-margin-bottom: 2rem;
    --contextualOffer-majorOffers-margin-bottom: 2rem;
    --contextualOffer-minorOffers-margin-bottom: 2rem;
    --contextualOffer-seasonsTabs-margin-bottom: 2rem;
    --contextualOffer-voucher-margin-bottom: 2rem;
    --contextualOffer-alertBox-margin-bottom: 2rem;
    --contextualOffer-voucher-max-width: 100%;
  }

  width: 100%;

  &__title {
    margin-bottom: var(--contextualOffer-title-margin-bottom);
  }

  &__offerHeader {
    margin-bottom: var(--contextualOffer-offerHeader-margin-bottom);
  }

  &__majorOffers {
    margin-bottom: var(--contextualOffer-majorOffers-margin-bottom);
  }

  &__minorOffers {
    margin-bottom: var(--contextualOffer-minorOffers-margin-bottom);
  }

  &__seasonsTabs {
    margin-bottom: var(--contextualOffer-minorOffers-margin-bottom);
  }

  &__alertBox {
    margin-bottom: var(--contextualOffer-alertBox-margin-bottom);
  }

  &__voucher {
    max-width: var(--contextualOffer-voucher-max-width);
    margin-bottom: var(--contextualOffer-voucher-margin-bottom);
  }

  &__button {
    margin-top: 1.5rem;
  }
}
